@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-black: #000000;
  --theme-dark: #191919;
  --theme-blue: #2a4978;
  --theme-mid: #2D4263;
  --theme-accent: #C84B31;
  --theme-light: #ECDBBA;
  /* --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255; */
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

/* #191919 - black */
/* #2D4263 - blue */
/* #C84B31 - red */
/* #ECDBBA - beige */

@media (prefers-color-scheme: dark) {
  :root {
    /* --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0; */
  }
}

body {
  /* font-family: 'Sorts Mill Goudy', sans-serif; */
  font-family: 'Goudy Bookletter 1911', monospace;
  /* font-family: 'Inter', sans-serif; */
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  background: #ECDBBA;
  padding-bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
  margin-bottom: 0;
  margin-bottom: env(safe-area-inset-bottom, 0);
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

h2 {
  font-size: 18px;
  font-weight: regular;
}

input ~ .dot {
  background-color: #999;
  border: 4px solid white;
}

input:checked ~ .dot {
  transform: translateX(100%);
  border: 2px solid white;
  /* background-color: #2D4263; */
  background-color: var(--theme-blue);
}

input ~ .dot2 {
  background-color: #999;
  border: 4px solid white;
}

input:checked ~ .dot2 {
  transform: translateX(100%);
  border: 2px solid white;
  /* background-color: #2D4263; */
  background-color: var(--theme-black);
}

input ~ .dot3 {
  background-color: var(--theme-accent);
  border: 2px solid #ffffff;
}

input:checked ~ .dot3 {
  transform: translateX(100%);
  border: 2px solid #ffffff;
  background-color: var(--theme-accent);
  /* background-color: var(--theme-black); */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    /* transition: translateY(-300px); */
    transition: translateY(0%);
  }
  to {
    /* transition: translateY(0px); */
    transition: translateY(100%);
  }
}

.fadeIn {
  animation: fadeIn .25s ease-out;
}

.slideIn {
  animation: slideIn 5s 5s ease-out;
}

.extrasFadeIn1 {
  animation: fadeIn 1s 1.2s ease-out;
}

.extrasFadeIn2 {
  animation: fadeIn 1s 1.4s ease-out;
}

.extrasFadeIn3 {
  animation: fadeIn 1s 1.6s ease-out;
}

.extrasFadeIn4 {
  animation: fadeIn 1s 1.8s ease-out;
}

.extrasFadeIn5 {
  animation: fadeIn 1s 1.1s ease-out;
}

.extrasFadeIn6 {
  animation: fadeIn 1s ease-in-out;
}

.fadeInAccount {
  animation: fadeIn .25s 1s  ease-out;
}